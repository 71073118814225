import { useRouter } from "next/router";
import { useEffect } from "react";
import { Authorized } from "../../shared/Auth/Authorized";

export default function ClientApp() {
  const router = useRouter();

  useEffect(() => {
    router.push("/app/home", undefined, {
      shallow: true,
    });
  }, []);

  return (
    <Authorized
      hideMessage={false}
      allowedUserTypes={["Client", "Affiliate", "Therapist", "SuperUser"]}
    />
  );
}
